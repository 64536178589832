<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link to="/" class="d-flex align-center">
            <v-img
              v-if="mode == 'PRODUCTION'"
              :src="require('@/assets/images/logos/logo.png')"
              max-width="250px"
              alt="logo"
              contain
              class="me-3"
            ></v-img>
            <h2 v-else>{{ mode }}</h2>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="mb-2">Silahkan Login untuk menggunakan aplikasi</p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form>
            <v-text-field
              v-model="email"
              outlined
              label="Username"
              placeholder="xxx"
              hide-details
              class="mb-3"
              @keydown.enter="login"
            ></v-text-field>

            <v-text-field
              v-model="password"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Password"
              placeholder="············"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details
              @click:append="isPasswordVisible = !isPasswordVisible"
              @keydown.enter="login"
            ></v-text-field>
            <VueRecaptcha
              :sitekey="siteKey"
              @verify="verifyMethod"
              :load-recaptcha-script="true"
              @error="handleError"
            ></VueRecaptcha>
            <div class="d-flex align-center justify-space-between flex-wrap"></div>

            <v-btn block color="primary" class="mt-6" :loading="loading" @click="login"> Login </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { VueRecaptcha } from 'vue-recaptcha'
import axios from 'axios'

const mode = process.env.VUE_APP_MODE
const apiRoot = process.env.VUE_APP_APIROOT
export default {
  components: {
    VueRecaptcha,
  },
  setup() {
    const isPasswordVisible = ref(false)
    const email = ref('')
    const password = ref('')
    const recaptcha = ref('')
    const siteKey = ref(process.env.VUE_APP_CAPTCHA_KEY)
    const handleError = () => {
      // Do some validation
    }
    function verifyMethod(response) {
      this.recaptcha = response
    }
    return {
      isPasswordVisible,
      email,
      password,
      siteKey,
      recaptcha,
      handleError,
      verifyMethod,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
  data() {
    return {
      loading: false,
      mode: mode,
    }
  },
  methods: {
    login() {
      this.loading = true
      const fmData = new FormData()
      fmData.append('username', this.email)
      fmData.append('password', this.password)
      fmData.append('recaptcha', this.recaptcha)
      axios
        .post(`${apiRoot}/Auth/login`, fmData)
        .then(response => {
          if (response.data.code === 200) {
            const data = {
              token: response.data.token,
            }
            this.$store.dispatch('login', data)
            this.$router.push('/')
          } else {
            alert(response.data.message)
          }
          this.loading = false
        })
        .catch(error => {
          alert(error)
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
